@import url('https://fonts.googleapis.com/css2?family=Rosario:ital,wght@1,300&display=swap');
@import "rsuite/dist/rsuite.css";
.rs-btn-link:disabled, .rs-btn-link.rs-btn-disabled{
    display: none;
}
:root,
body {
    margin: 0px;
    padding: 0px;
    font-family: 'Rosario', sans-serif;
    scroll-behavior: smooth;
        --primary: #fff;
        --secondary: #101010;
        --mt-bg:#faebd7;
        --mt-text: #101010;
        --mt-primary:#fcfcfc;
        --sr-card-bg:#fcfcfc;
}
body.darkmode {
    --primary: #101010;
    --secondary: #fff;
    --mt-bg: #101010;
    --mt-text: #faebd7;
    --mt-primary: #000;
    --sr-card-bg: #1e1e1e;
}


.t-datepicker {
    outline: none;
    border: #dee2e6 2px solid;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
    padding: 35px 5px 5px 0px;
    font-weight: 500;
    font-size: 16px;
}

.t-check-in,
.t-check-out {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.t-datepicker .t-date-info-title {
    font-size: 14px !important;
}

@media screen and (min-device-width: 700px) and (max-device-width: 800px) {
    .t-datepicker .t-date-info-title {
        font-size: 12px !important;
    }

    .t-dates {
        padding: 5px 10px !important;
        font-size: 12px !important;
    }

    /* .rs-picker-popup {
        position: fixed; 
        top: 500 !important; 
        right:100px;
        left: 0px;
        top: 500px;
        z-index: 1000;
      } */
}

.t-check-in {
    border-right: 2px solid #f0808075 !important;
}

.guests-input {
    position: relative;
    width: 100%;
    height: 70%;
    margin: 0 auto;
}

.guests-input button {
    cursor: pointer;
    text-overflow: ellipsis;
    outline: none;
    border-radius: 10px;
    padding: 20px 5px 5px 0px;
    font-weight: 500;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 15%);
    border: #dee2e6 2px solid;
    font-size: 14px;
    width: 100%;
    height: 80%;
    background-color: white;
}

.guests-input button:before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 15px
}

.guests-input button:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    right: 21px
}

.guests-input button.open:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.guests-input button.open:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.guests-input__options {
    position: absolute;
    width: 100%;
    background-color: var(--primary);
    color: var(--secondary);
    -webkit-box-shadow: rgba(72, 72, 72, 0.2) 0px 15px 20px;
    box-shadow: rgba(72, 72, 72, 0.2) 0px 15px 20px;
    border-radius: 2px;
    overflow: hidden;
    z-index: 1;
    height: 0;
    opacity: 0;
    margin-top: 5px;
    border-radius: 10px;
    -webkit-transition: all .1s linear;
    transition: all .1s linear
}

.guests-input__options.open {
    opacity: 1;
    height: auto
}

.guests-input__options>div {
    padding: 10px 0;
    text-align: center
}

.guests-input__options>div:first-child {
    padding-top: 35px
}

.guests-input__options>div:last-child {
    padding-bottom: 35px
}

.guests-input__ctrl {
    display: inline-block;
    border: 1px solid salmon;
    font-size: 20px;
    color: salmon;
    padding: 3px 8px;
    line-height: 20px;
    border-radius: 2px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.guests-input__ctrl.disabled {
    color:#d8d8d8;
    border-color: #d8d8d8;
    cursor: default
}

.guests-input__value {
    display: inline-block;
    padding: 0 10px;
    width: 160px;
    cursor: default
}

.guests-input__value span {
    display: inline-block;
    padding-right: 5px
}
.rmdp-input{
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}
.rmdp-input:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}
.rmdp-container{
    outline: none !important;
        background: var(--primary);
        border: var(--primary) 2px solid;
        color: var(--secondary);
        box-shadow: none;
        border-radius: 10px;
        padding: 10px;
        font-weight: 500;
        width: 100%;
        font-size: 15px;
        border: 1px solid #1e1e1e;
}
.rmdp-calendar{
    height: max-content;
    padding: 10px;
    border-radius: 8px; /* Adjust border radius as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adjust shadow as needed */
    background-color: #ece6e6; /* Set background color */
    width: 100%;
}

.rmdp-wrapper {
    background: var(--primary) !important;
}
.rmdp-day-hidden, .rmdp-day.rmdp-disabled {
    cursor: default;
    pointer-events: none;
    background-color: #ccc;
}
.rmdp-day {
    /* color: var(--secondary) !important; */
}

.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    /* padding: 0px 15px; */
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
}
.rmdp-header-values {
    /* color: salmon !important; */
}

.rmdp-week-day {
    /* color: salmon !important; */
}
.rmdp-week{
  display: flex;
  justify-content: space-between;
  width: 285px;

}

.rmdp-shadow {
    box-shadow: none !important;
}
.rmdp-arrow {
    display:flex;
    border: solid #012169 !important;
    border-width: 0px 2px 2px 0px !important;
    color: white;
}
.rmdp-arrow-container:hover{

        background: var(--secondary) !important;
}
.rmdp-range {
    background: #012169 !important;
    color: white;
}

.rmdp-range-hover {
    background: #012169 !important;
    color: white;
}


.rmdp-day.rmdp-today span {
    background: var(--secondary) !important;
    color: var(--primary) !important;
}

.rmdp-month-picker{
    background: var(--primary) !important;
}
.rmdp-year-picker{
        background: var(--primary) !important;
}
.rmdp-day span:hover{
    color:var(--primary) !important;
            background: var(--secondary) !important;
}
.rmdp-ep-arrow[direction=top] {
    border-bottom: 1px solid var(--primary) !important;
}

.offcanvas-header{
    background: var(--mt-bg);
    display: none;
}
/* .offcanvas-body{
    background: var(--mt-bg);
} */
 .close-button{
    position: absolute;
    right: .5rem;
    top: .5rem;
    background: transparent ;
    border:  none;
}
 .close-button i{
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #012169;
    color: var(--mt-bg);
    border-radius: 20px;
    font-size: 1.5rem;
    border: none;
}
.modal-header {
    /* background: var(--mt-bg); */
    height: 4rem !important;
    border-bottom: none !important;
  
}


  
  