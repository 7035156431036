/* Styling for the modal container */
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Adjust the z-index to control the stacking order */
    overflow: hidden; /* Prevent background scrolling when modal is open */
  }
  
  /* Styling for the modal content */
  .modal-content {
    background-color: #fff; /* Background color of the modal */
    padding: 2%;
    border-radius: 40px; /* Add border radius for a rounded appearance */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 100%;
    height: 100%;
    overflow-y: auto; /* Enable vertical scrolling if the content overflows */
    text-align: center; /* Center text content */
    position: relative; /* Position for top buttons */
  }
  
  /* Close button styling (optional) */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #000000;
  }
  
  /* Styling for the buttons */
  .modal-buttons {
    margin-top: 20px;
  }
  
  /* Styling for the "Continue with Google" button */
  .google-button {
    background-color: #fff; /* White background */
    color: #000; /* Black text color */
    padding: 10px 80px;
    border: 1px solid #d1d1d1; /* Black border */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Styling for the "Continue with Facebook" button */
  .facebook-button {
    background-color: #fff; /* White background */
    color: #000; /* Black text color */
    padding: 10px 73px;
    border: 1px solid #d1d1d1; /* Black border */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

 
  
  /* Styling for the top buttons */
  /* .top-buttons {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  } */
  